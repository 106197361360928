@include media-breakpoint-down(xxl) {
  .main-wrapper {
    .btn.btn-nav {
      width: 150px;
      font-size: 18px;
      padding: {
        top: 30px;
        bottom: 30px;
      }
    }
    .address {
      ul li {
        font-size: 18px;
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .top-wrapper.overflow-hidden {
    overflow: visible !important;
  }
  .main-wrapper {
    .content {
      border-radius: 0;
      margin: 0 !important;
      padding: 10px !important;
      &.position-absolute {
        position: static !important;
      }
      .inner.overflow-auto {
        overflow: visible !important;
      }
    }
    .btn.btn-nav {
      width: 100px;
      font-size: 16px;
      padding: {
        top: 20px;
        bottom: 20px;
      }
    }
    .address {
      ul li {
        font-size: 16px;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .main-wrapper {
    .btn.btn-nav {
      width: 200px;
      font-size: 22px;
      padding: {
        top: 50px;
        bottom: 50px;
      }
    }

    .address {
      ul li {
        font-size: 22px;
      }
    }

    .footer.position-absolute {
      position: static !important;
      padding: 1rem;
    }
  }
}