// Body
$info: #949fd0;
$primary: #94D0CC;
$success: #94d09e;
$warning: #EEC4C4;
$danger: #eb6161;
$secondary: #D1D9D9;
$dark: #333;
$body-bg: $warning;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;
$link-color: $danger;

// modal
$modal-content-bg: $warning;
$modal-content-border-color: none;
$modal-header-border-color: $primary;
$modal-footer-border-color: $primary;

// form
$input-bg: $secondary;
$input-border-color: $primary;
$input-group-addon-bg: $secondary;
$input-group-addon-border-color: $primary;
$input-group-addon-color: $primary;
$input-disabled-bg: $secondary;

// custom var
@for $i from 1 through 10 {
  .z-#{$i} {
    z-index: $i;
  }
}