@import 'animations';

@mixin animation($name, $delay) {
  @include prefix-property(animation-name, $name);
  @include prefix-property(animation-duration, .5s);
  @include prefix-property(animation-delay, $delay);
  @include prefix-property(animation-fill-mode, forwards);
}

@mixin animation2($name) {
  @include prefix-property(animation-name, $name);
  @include prefix-property(animation-duration, .82s);
}

.main-wrapper {
  &::before {
    content: '';
    width: 100vw;
    height: 100vw;
    border-radius: 50%;
    background-color: $primary;
    position: absolute;
    left: -20%;
    top: -5%;
    display: block;
    z-index: 0;
  }

  .btn.btn-nav {
    width: 200px;
    font-size: 22px;
    padding: {
      top: 50px;
      bottom: 50px;
    }
  }

  .content {
    border-radius: 20px;
    font-size: 22px;
  }
  .address {
    ul li {
      font-size: 22px;
    }
  }
}

form fieldset {
  font-weight: bold;
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-left: none
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu), .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3) {
  border-right: none;
}

.invalidAct {
  @include animation2(shaken);
}

.fade-in {
  opacity: 0;
  @include animation(fade_in, 1s);
}

.pop-in {
  opacity: 0;
  @include animation(pop_in, 0s);
}

.slide-up-in {
  opacity: 0;
  @include animation(slide_up_in, .5s);
}

.slide-down-in {
  opacity: 0;
  @include animation(slide_down_in, .5s);
}

@import 'responsive';

/* width */
::-webkit-scrollbar {
  width: 20px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px $info; 
  background-color: $primary;
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: $warning;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $danger; 
}