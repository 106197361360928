@mixin keyframes-animation($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

@mixin prefix-property($name, $value) {
  -webkit-#{$name}: $value;
          #{$name}: $value;
}

@include keyframes-animation(fade_in) {
  0% {
    @include prefix-property(opacity, 0);
  }
  100% {
    @include prefix-property(opacity, 1);
  }
}

@include keyframes-animation(slide_up_in) {
  0% {
    @include prefix-property(transform, translateY(-100px));
    @include prefix-property(opacity, 0);
  }
  100% {
    @include prefix-property(opacity, 1);
    @include prefix-property(transform, translateY(0));
  }
}

@include keyframes-animation(slide_down_in) {
  0% {
    @include prefix-property(transform, translateY(100px));
    @include prefix-property(opacity, 0);
  }
  100% {
    @include prefix-property(opacity, 1);
    @include prefix-property(transform, translateY(0));
  }
}

@include keyframes-animation(pop_in) {
  0% {
    @include prefix-property(transform, scale(.2));
    @include prefix-property(opacity, 0);
  }
  100% {
    @include prefix-property(opacity, 1);
    @include prefix-property(transform, scale(1));
  }
}

@include keyframes-animation(shaken) {
  10%, 90% {
    @include prefix-property(transform, translateX(2px));
  }

  20%, 80% {
    @include prefix-property(transform, translateX(-2px));
  }

  30%, 50%, 70% {
    @include prefix-property(transform, translateX(5px));
  }

  40%, 60% {
    @include prefix-property(transform, translateX(-5px));
  }
}